import React, { useEffect } from "react";
// @ts-ignore
import styled from "@xstyled/styled-components";
import { useLocation, useParams, withRouter } from "react-router-dom";
import { Header } from "../../components/header/Header";
import { renderRoutes } from "react-router-config";
import { useDispatch, useSelector } from "react-redux";
import {
  selectIsAppLoading,
  selectLanguageStrings,
  selectLanguagesList,
  setLanguage,
} from "../../app/state/appSlice";
import { View } from "../../routes/routeInterfaces";
import { Footer } from "../../components/footer/Footer";
import { useGetLanguages } from "../../api/languages";
import { Loader } from "../../components/placeholders/Loader";
import ls from "../../utils/ls";
import { LsValueType } from "../../enums/LsValueType";
import { MAPPING, getCurrentPath } from "../../routes/paths";
import { useCustomHistory } from "../../utils/react-router-dom-abstraction";
import { UserView } from "../../enums/View";

const Wrapper = styled.div``;

export const deafMenuArr = (languageStrings: any) => [
  {
    id: "movies",
    label: languageStrings ? languageStrings.mooviesLabel : "Moovies",
    to: View.DEAF_MOVIES,
  },
  {
    id: "sermons",
    label: languageStrings ? languageStrings.sermonsLabel : "Sermons",
    to: View.DEAF_SERMONS,
  },
  {
    id: "lessons",
    label: languageStrings ? languageStrings.lessonsLabel : "Lessons",
    to: View.DEAF_LESSONS,
  },
];

const Deaf = (props: any) => {
  useGetLanguages();
  const languagesArr = useSelector(selectLanguagesList);
  const languageStrings = useSelector(selectLanguageStrings);
  const isAppLoading = useSelector(selectIsAppLoading);
  const languageIdLS = ls.get(LsValueType.locale);
  const menuArr = deafMenuArr(languageStrings);

  const location = useLocation();
  const params = useParams();
  const history = useCustomHistory();
  const dispatch = useDispatch();

  const languageIdFromPath = location?.pathname.split("/")[2];
  const currentPath = getCurrentPath(location.pathname, params);

  useEffect(() => {
    const locale =
      languagesArr &&
      (languagesArr.find((item: any) => item.id === languageIdFromPath) as {
        name: string;
        id: string;
      });

    if (!languageIdLS && locale) {
      ls.set(LsValueType.locale, locale);
      dispatch(setLanguage(locale));
    }
  }, [languagesArr, languageIdLS, languageIdFromPath]);

  useEffect(() => {
    const isCurrentRouteExist =
      Object.values(MAPPING).filter((item) => item.path === currentPath)
        .length > 0;

    if (!isCurrentRouteExist) {
      history.push(UserView.USER_HOME, {
        pathParams: { languageKey: "en-GB" },
      });
    }
  }, [currentPath]);

  return (
    <Wrapper>
      <Header menuArr={menuArr} />
      {renderRoutes(props.route.routes)}
      <Footer languageStrings={languageStrings} />
      {isAppLoading && <Loader />}
    </Wrapper>
  );
};

export default withRouter(Deaf);
